// eslint-disable-next-line import/prefer-default-export
export default function reportsMenuItems(active, params = {}) {
  const menuItems = [
    {
      name: 'settings-reports-list',
      layout: 'Reports',
    },
  ]
  menuItems.forEach(item => {
    // eslint-disable-next-line no-param-reassign
    item.active = item.name === active
    // eslint-disable-next-line no-param-reassign
    item.route = { name: item.name, params }
  })

  return {
    menuItems,
  }
}
