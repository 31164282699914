<template>
  <sub-menu-component
      :menu-items="menuItems"
  >
    <router-view @updateMenu="updateMenu" />
  </sub-menu-component>
</template>

<script>
import { ref } from '@vue/composition-api'
import reportsMenuItems from '@/views/components/sub-menu/settings-sub-menu/reports'

export default {
  name: 'Reports',
  setup() {
    const menuItems = ref()
    return {
      menuItems,
    }
  },
  methods: {
    updateMenu(active) {
      this.menuItems = reportsMenuItems(active).menuItems
    },
  },
}
</script>

<style scoped>

</style>
